import isTestRunner from '@anm/helpers/is/isTestRunner';
import React, { useEffect, FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';

import vtConfig from '../../../config';

import { CaptchaFieldWrapper, TextError } from './Wrapper';

type TextFieldProps = FieldRenderProps<never, HTMLInputElement>;

const CaptchaField: FC<TextFieldProps> = ({
  meta: { touched, error },
  input: { onChange }
}) => {
  const captchaApiKey = vtConfig().captcha.apiKey;

  useEffect(() => {
    (async () => {
      (await isTestRunner()) && onChange('this is test runner');
    })();
  }, []);

  return (
    <CaptchaFieldWrapper>
      <ReCAPTCHA sitekey={captchaApiKey} onChange={onChange} />
      {touched && error && <TextError variant="static">{error}</TextError>}
    </CaptchaFieldWrapper>
  );
};

export default CaptchaField;
