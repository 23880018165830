import compactProps from '@anm/helpers/compactProps';

import { FieldValidators, SubmitFormFields } from './types';

const bindValidators = (validators: FieldValidators) => async (
  fields: SubmitFormFields
) => {
  type Key = keyof SubmitFormFields;
  const result = {} as {
    [key: string]: string | null;
  };

  let key: Key;
  const fieldKeys = Object.keys(validators) as Key[];
  for (key of fieldKeys) {
    const fieldValue = fields[key];
    const currentFieldValidator = validators[key] || (() => true);
    result[key] = await currentFieldValidator(fieldValue);
  }

  return result;
};

export const validateForm = (validators: FieldValidators) => async (
  fields: SubmitFormFields
) => {
  if (!validators) return;
  const validatedFields = await bindValidators(validators)(fields);
  return compactProps(validatedFields);
};
