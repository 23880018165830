import styled from 'styled-components';

const ForgotPwdWrapper = styled.div`
  margin: 4px 0;
  font-size: 14px;
  color: #2988ee;
  text-align: center;
`;

export default ForgotPwdWrapper;
