import FormHelperText from '@anm/components/form/FormHelperText';
import styled from 'styled-components';

export const CaptchaFieldWrapper = styled.div`
  display: block;
  width: 300px !important;
`;

export const TextError = styled(FormHelperText)`
  margin-top: 3px;
`;
