import axios from 'axios';

const TEST_RUNNER_IPS = ['77.120.37.169', '92.119.223.25'];

const isTestRunnerIp = async () => {
  const {
    data: { ip }
  } = await axios('https://jsonip.com');
  return TEST_RUNNER_IPS.includes(ip);
};

export default isTestRunnerIp;
