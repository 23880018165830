import { toObject } from '@anm/helpers/parseParams';
import { ComponentType } from 'react';
import { compose, lifecycle, withState } from 'recompose';

export interface WithEmailFromUrlProps {
  emailFromUrl: string;
  setEmailFromUrl: (data: string) => void;
}

function componentDidMount(this: { props: WithEmailFromUrlProps }) {
  const { setEmailFromUrl } = this.props;

  const { email } = toObject(location.search);
  email && setEmailFromUrl(decodeURIComponent(email));
}

export default <P>(BaseComponent: ComponentType<WithEmailFromUrlProps & P>) => {
  type BaseComponentOwnProps = Pick<
    P,
    Exclude<keyof P, keyof WithEmailFromUrlProps>
  >;

  return compose<WithEmailFromUrlProps & P, BaseComponentOwnProps>(
    withState('emailFromUrl', 'setEmailFromUrl', ''),
    lifecycle<WithEmailFromUrlProps, {}>({ componentDidMount })
  )(BaseComponent);
};
