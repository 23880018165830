import { RouteParams } from 'next-routes';
import { FC } from 'react';
import styled from 'styled-components';

import routes from '../../routes';

import ForgotPwdWrapper from './Wrapper';

const { Link } = routes;

const ForgotLink = styled.span`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 30px;
  margin: -15px auto 0;
  position: relative;
  z-index: 5;
  cursor: pointer;
`;

type ForgotPwdProps = {
  email?: string;
};

const ForgotPwd: FC<ForgotPwdProps> = ({ email }) => {
  const params = { ...(email && { email }) } as RouteParams;

  return (
    <ForgotPwdWrapper>
      <Link route="recovery" params={params}>
        <a>
          <ForgotLink>Forgot password?</ForgotLink>
        </a>
      </Link>
    </ForgotPwdWrapper>
  );
};

export default ForgotPwd;
