import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import FormHelperText from '@anm/components/form/FormHelperText';
import toCamelCase from '@anm/helpers/string/toCamelCase';
import React, { FC } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { compose, mapProps } from 'recompose';

import withEmailFromUrl from '../../../HOCs/withEmailFromUrl';
import ForgotPwd from '../../ForgotPwd';

import { validateForm } from './helpers';
import { HOCs, RenderFormProps, SubmitFormProps } from './types';

const { Provider, Consumer } = React.createContext<Partial<SubmitFormProps>>(
  {}
);
export const SubmitFormProvider = Provider;

const renderForm = ({
  isPending,
  forgotLink,
  submitError,
  renderFields,
  buttonText = 'Submit',
  buttonVariant = 'light-blue-lowercase'
}: RenderFormProps) => (props: FormRenderProps) => {
  const { handleSubmit, values, errors, touched, active } = props;
  const fieldsParams = {
    touched,
    errors,
    activeField: active,
    password: values.password
  };

  return (
    <form onSubmit={handleSubmit}>
      {renderFields(fieldsParams)}
      {forgotLink && <ForgotPwd email={values.email} />}
      {submitError && (
        <FormHelperText variant="static">{submitError}</FormHelperText>
      )}

      <ButtonLoader
        size="large"
        type="submit"
        variant={buttonVariant}
        isPending={isPending}
        data-button={toCamelCase(buttonText)}
      >
        {buttonText || 'Submit'}
      </ButtonLoader>
    </form>
  );
};

const SubmitForm: FC<HOCs & SubmitFormProps> = ({
  isPending,
  forgotLink,
  validators,
  initialData,
  submitError,
  renderFields,
  children: buttonText,
  ...otherProps
}) => (
  <Consumer>
    {({ buttonVariant }) => (
      <Form
        render={renderForm({
          isPending,
          forgotLink,
          submitError,
          renderFields,
          buttonText: buttonText as string,
          buttonVariant: buttonVariant!
        })}
        validate={validateForm(validators!)}
        initialValues={initialData}
        {...otherProps}
      />
    )}
  </Consumer>
);

export default compose<HOCs, SubmitFormProps>(
  withEmailFromUrl,
  mapProps(({ emailFromUrl, ...otherProps }) => ({
    initialData: { email: emailFromUrl },
    ...otherProps
  }))
)(SubmitForm);
